<template>
    <v-card class="mx-auto" max-width="600">
        <v-card-title class="justify-center">
            {{ $t("auth.login") }}
        </v-card-title>
        <v-card-text>
            <v-form ref="form" @submit.prevent="onSubmit">
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="form.email"
                            :label="$t('users.user.email')"
                            prepend-icon="mdi-account-outline"
                            :rules="requiredRule"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="form.password"
                            :type="showPassword ? 'text' : 'password'"
                            :label="$t('users.user.password')"
                            prepend-icon="mdi-lock-outline"
                            :append-icon="
                                showPassword
                                    ? 'mdi-eye-outline'
                                    : 'mdi-eye-off-outline'
                            "
                            @click:append="showPassword = !showPassword"
                            :rules="requiredRule"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="isLoading"
                        >
                            {{ $t("button.submit") }}
                        </v-btn>
                        <br />
                        <router-link
                            class="primary--text text-decoration-none my-2"
                            :to="{ name: 'Register' }"
                        >
                            {{ $t("auth.loginToRegister") }}
                        </router-link>
                        <br />
                        <router-link
                            class="primary--text text-decoration-none my-2"
                            :to="{ name: 'ForgottenPassword' }"
                        >
                            {{ $t("auth.loginToPassword") }}
                        </router-link>
                        <p v-if="error" class="error--text">
                            {{ $t("auth.loginError") }}
                        </p>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            form: {
                email: "",
                password: "",
            },
            showPassword: false,

            isLoading: false,
            error: false,
        };
    },

    computed: {
        ...mapState({
            homePage: (state) => state.auth.homePage,
            requiredRule: (state) => state.utils.requiredRule,
        }),
    },

    watch: {
        "form.email": function() {
            this.error = false;
            this.form.email = this.form.email.toLowerCase();
        },
        "form.password": function() {
            this.error = false;
        },
    },

    mounted() {
        this.$recaptchaLoaded().then(() => {
            this.$recaptchaInstance.showBadge();
        });
    },

    methods: {
        onSubmit() {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                this.error = false;

                this.$recaptchaLoaded().then(() => {
                    this.$recaptcha("login").then((token) => {
                        this.form.captcha = token;

                        this.$store
                            .dispatch("auth/login", this.form)
                            .then(() => {
                                this.$router.push({ name: this.homePage });
                            })
                            .catch(() => {
                                this.isLoading = false;
                                this.error = true;
                            });
                    });
                });
            }
        },
    },

    beforeDestroy() {
        this.$recaptchaInstance.hideBadge();
    },
};
</script>
